
// BluetoothPrinterService.js
class BluetoothPrinterService {
    constructor() {
      this.device = null;
      this.server = null;
      this.service = null;
      this.characteristic = null;
    }
  
    // Conectar a la impresora
    async connectToPrinter() {
      try {
        const selectedDevice = await navigator.bluetooth.requestDevice({
          acceptAllDevices: true,
          optionalServices: ['00001101-0000-1000-8000-00805f9b34fb'],
        });
        
        this.device = selectedDevice;
        this.server = await selectedDevice.gatt.connect();
        this.service = await this.server.getPrimaryService('00001101-0000-1000-8000-00805f9b34fb');
        this.characteristic = await this.service.getCharacteristic('00001101-0000-1000-8000-00805f9b34fb');
  
        console.log("Conectado a la impresora:", this.device.name);
        return true;
      } catch (error) {
        console.error("Error al conectar a la impresora:", error);
        return false;
      }
    }
  
    // Imprimir datos
    async printData(data) {
      if (!this.characteristic) {
        console.error("No se ha conectado a la impresora");
        return;
      }
  
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);
  
      try {
        await this.characteristic.writeValue(encodedData);
        console.log("Impresión completada");
      } catch (error) {
        console.error("Error al imprimir:", error);
      }
    }
  
    // Desconectar de la impresora
    async disconnectPrinter() {
      if (this.device && this.device.gatt.connected) {
        await this.device.gatt.disconnect();
        console.log("Desconectado de la impresora");
      }
    }
  }
  
  // Exportar una instancia única del servicio para que sea reutilizable
  const bluetoothPrinterService = new BluetoothPrinterService();
  export default bluetoothPrinterService;
  