import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Typography } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Dashboard = () => {
  const [vehicleData, setVehicleData] = useState({
    camiones: 120,
    tractomulas: 50,
    vehiculosPolicia: 30,
    otros: 70,
  });

  const [vehicleStats, setVehicleStats] = useState([
    { day: '2024-11-01', count: 30 },
    { day: '2024-11-02', count: 40 },
    { day: '2024-11-03', count: 50 },
    { day: '2024-11-04', count: 45 },
    { day: '2024-11-05', count: 60 },
  ]);

  // Simulando la carga de datos
  useEffect(() => {
    // Aquí cargarías los datos de un API o base de datos
  }, []);

  return (
    <div style={{ padding: '24px', background: '#fff' }}>
      <Title level={2}>Dashboard</Title>

      {/* Tarjetas con el conteo de vehículos */}
      <Row gutter={24}>
        <Col span={6}>
          <Card title="Camiones" bordered={false}>
            <Statistic
              title="Cantidad"
              value={vehicleData.camiones}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<InfoCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Tractomulas" bordered={false}>
            <Statistic
              title="Cantidad"
              value={vehicleData.tractomulas}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<InfoCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Vehículos de Policía" bordered={false}>
            <Statistic
              title="Cantidad"
              value={vehicleData.vehiculosPolicia}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<InfoCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Otros" bordered={false}>
            <Statistic
              title="Cantidad"
              value={vehicleData.otros}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              prefix={<InfoCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* Gráfico de barras con estadísticas de vehículos por día */}
      <div style={{ marginTop: '24px' }}>
        <Title level={4}>Estadísticas de vehículos por día</Title>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={vehicleStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
