import React, { useState, useEffect } from 'react';
import { Card, Table, Modal, Form, Button, Select, DatePicker, message, Tag, ConfigProvider, Input } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import esES from 'antd/es/locale/es_ES';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import API_BASE_URL from '../components/apiService';

moment.locale('es');

const Jornadas = () => {
  const [jornadas, setJornadas] = useState([]);
  const [ferrys, setFerrys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  const fetchJornadas = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/jornadas/all`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          page: currentPage,
          limit: 5,
        },
      });

      const { count, jornadas } = response.data.data;
      setJornadas(jornadas);
      setTotal(count);
    } catch (error) {
      message.error('Error al cargar las jornadas');
    } finally {
      setLoading(false);
    }
  };

  const fetchFerrys = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/ferrys/all`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const ferrysData = response.data.data.ferrys || [];
      setFerrys(ferrysData);
    } catch (error) {
      message.error('Error al cargar los ferrys');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchJornadas();
  };

  const showModal = () => {
    fetchFerrys();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCreate = async (values) => {
    setLoading(true);
    const token = getAuthToken();

    try {
      // Obtener la fecha de inicio
      const fechaInicio = values.fecha_inicio;
      // Crear la fecha de fin concatenando la hora 23:59:59
      const fechaFinString = fechaInicio.format('YYYY-MM-DD') + " 23:59:59"; // Concatenamos la hora 23:59:59
      const fechaFin = moment(fechaFinString, 'YYYY-MM-DD HH:mm:ss'); // Convertimos la cadena a un objeto moment

      const data = {
        ...values,
        fecha_inicio: fechaInicio ? fechaInicio.format('YYYY-MM-DD HH:mm:ss') : null,
        fecha_fin: fechaFin.format('YYYY-MM-DD HH:mm:ss'),  // Establecer la fecha de fin correctamente
      };

      const response = await axios.post(`${API_BASE_URL}/admin/`, data, {
        headers: {
          Authorization: `${token}`,
        },
      });

      message.success('Jornada creada correctamente');
      setIsModalOpen(false);
      fetchJornadas();
      form.resetFields();
    } catch (error) {
      message.error('Error al crear la jornada');
    } finally {
      setLoading(false);
    }
  };

  const toggleJornadaStatus = async (id_jornada, currentStatus) => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      const newStatus = currentStatus === 1 ? 0 : 1;

      const response = await axios.post(
        `${API_BASE_URL}/admin/jornadas/status`,
        { id_jornada, estado: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        message.success(`La jornada ha sido marcada como ${newStatus === 1 ? 'Activa' : 'Inactiva'}`);
        fetchJornadas();
      } else {
        message.error('Error al actualizar el estado de la jornada');
      }
    } catch (error) {
      message.error('Error al actualizar el estado de la jornada');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: 'ID Jornada', dataIndex: 'id_jornada', key: 'id_jornada' },
    { title: 'Ferry', dataIndex: 'ferry_nombre', key: 'ferry_nombre' },
    { 
      title: 'Fecha Inicio', 
      dataIndex: 'fecha_inicio', 
      key: 'fecha_inicio',
      render: (_, record) => (
        <span>{moment(record.fecha_inicio).format('YYYY-MM-DD HH:mm:ss')}</span>
      ),
      responsive: ['md'], // Mostrar solo en pantallas medianas o más grandes
    },
    // { 
    //   title: 'Fecha Fin', 
    //   dataIndex: 'fecha_fin', 
    //   key: 'fecha_fin',
    //   render: (_, record) => (
    //     <span>{moment(record.fecha_fin).format('YYYY-MM-DD HH:mm:ss')}</span>
    //   ),
    //   responsive: ['md'], // Mostrar solo en pantallas medianas o más grandes
    // },
    { title: 'Fecha inicial', dataIndex: 'fecha_inicio', key: 'fecha_inicio' },
    { title: 'Fecha fin', dataIndex: 'fecha_fin', key: 'fecha_fin' },
    { title: 'Usuario', dataIndex: 'usuario_nombre', key: 'usuario_nombre' },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (_, record) => (
        <Tag color={record.estado === 1 ? 'green' : 'red'}>
          {record.estado === 1 ? 'Activo' : 'Inactivo'}
        </Tag>
      ),
    },
    {
      title: 'Acción',
      key: 'action',
      render: (_, record) => (
        <Button
          type="primary"
          icon={record.estado === 1 ? <ClockCircleOutlined /> : <CheckCircleOutlined />}
          style={{ backgroundColor: record.estado === 1 ? '#f5222d' : '#52c41a', borderColor: record.estado === 1 ? '#f5222d' : '#52c41a' }}
          onClick={() => toggleJornadaStatus(record.id_jornada, record.estado)}
        >
          {record.estado === 1 ? 'Inactivar' : 'Activar'}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchJornadas();
  }, [currentPage]);

  const today = moment().startOf('day');

  useEffect(() => {
    if (ferrys.length > 0) {
      form.setFieldsValue({
        id_ferry: ferrys[0]?.id_ferry,
      });
    }
  }, [ferrys, form]);

  return (
    <ConfigProvider locale={esES}>
      <Card title="Administración de Jornadas">
        <Button type="primary" onClick={showModal} style={{ marginBottom: 20 }}>
          Crear Nueva Jornada
        </Button>

        <Table
          columns={columns}
          dataSource={jornadas}
          rowKey="id_jornada"
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: 5,
            total,
            onChange: handlePageChange,
          }}
          scroll={{ x: 'max-content' }} // Permite el desplazamiento horizontal
          responsive // Habilita la responsividad en la tabla
        />

        <Modal
          title="Crear Jornada"
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose
        >
          <Form
            form={form}
            onFinish={handleCreate}
            layout="vertical"
          >
            <Form.Item
              name="id_ferry"
              label="Ferry"
              rules={[{ required: true, message: 'Selecciona un ferry' }]} >
              <Select placeholder="Selecciona un ferry">
                {ferrys.map((ferry) => (
                  <Select.Option key={ferry.id_ferry} value={ferry.id_ferry}>
                    {ferry.ferry}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="fecha_inicio"
              label="Fecha de Inicio"
              rules={[{ required: true, message: 'Ingresa la fecha de inicio' }]} >
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '100%' }}
                disabledDate={(current) => current && current < today}
              />
            </Form.Item>
{/* 
            <Form.Item
              name="fecha_fin"
              label="Fecha de Fin"
              rules={[{ required: true, message: 'La fecha de fin es obligatoria' }]} >
              <Input
                value={form.getFieldValue('fecha_inicio') ? form.getFieldValue('fecha_inicio').format('YYYY-MM-DD') + " 23:59:59" : ''}
                readOnly
              />
            </Form.Item> */}

            <Button type="primary" htmlType="submit" block loading={loading}>
              Crear Jornada
            </Button>
          </Form>
        </Modal>
      </Card>
    </ConfigProvider>
  );
};

export default Jornadas;
