import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Modal, Form, Input, message, Tabs } from 'antd';
import axios from 'axios';
import { NumericFormat } from 'react-number-format'; // Usamos NumericFormat en lugar de NumberFormat
import API_BASE_URL from '../components/apiService';
import BluetoothPrinterConnect from './BluetoothPrinter';

// Función para obtener el token desde localStorage
const getAuthToken = () => {
  return localStorage.getItem('token'); // Obtener el token del localStorage
};

const Configuraciones = () => {
  const [tiposVehiculos, setTiposVehiculos] = useState([]); // Estado para los tipos de vehículos
  const [loading, setLoading] = useState(false); // Estado para manejar el loading
  const [total, setTotal] = useState(0); // Total de tipos de vehículos
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para abrir/cerrar el modal
  const [form] = Form.useForm(); // Usamos Form de Ant Design para el formulario

  // Función para obtener los tipos de vehículos desde el API
  const fetchTiposVehiculos = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/tipovehiculo/all`, {
        headers: {
          Authorization: `${token}`, // Pasamos el token en el header
        },
        params: {
          page: currentPage, // Paginación
          limit: 5, // Número de registros por página
        },
      });

      const { count, tipovehiculo } = response.data.data;
      setTiposVehiculos(tipovehiculo); // Guardamos los tipos de vehículos en el estado
      setTotal(count); // Total de tipos de vehículos
    } catch (error) {
      message.error('Error al cargar los tipos de vehículos');
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar el cambio de página
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTiposVehiculos(); // Recargar la lista de tipos de vehículos cuando cambias de página
  };

  // Llamada a fetchTiposVehiculos cuando el componente se monta o cuando cambia la página
  useEffect(() => {
    fetchTiposVehiculos();
  }, [currentPage]);

  // Función para mostrar el modal para agregar un nuevo tipo de vehículo
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  // Función para manejar el envío del formulario (crear tipo de vehículo)
  const handleCreate = async (values) => {
    setLoading(true);
    const token = getAuthToken();

    // Convertimos la tarifa a un número (sin formato)
    const tarifa = values.tarifa.replace(/[^0-9.-]+/g, ''); // Removemos los caracteres no numéricos

    try {
      const response = await axios.post(`${API_BASE_URL}/admin/tipovehiculo`, {
        tipo_vehiculo: values.tipo_vehiculo, // Obtenemos el nombre del tipo de vehículo
        tarifa: parseFloat(tarifa), // Convertimos la tarifa a un número y la enviamos
      }, {
        headers: {
          Authorization: `${token}`, // Pasar el token en el header
        },
      });

      message.success('Tipo de vehículo creado correctamente');
      setIsModalOpen(false);
      fetchTiposVehiculos(); // Recargar la lista de tipos de vehículos
      form.resetFields(); // Limpiar el formulario
    } catch (error) {
      message.error('Error al crear el tipo de vehículo');
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar la edición de la tarifa
  const handleEditTarifa = async (id, tarifa) => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      // Convertimos la tarifa a un número sin formato
      const tarifaNumerica = tarifa.replace(/[^0-9.-]+/g, '');

      const response = await axios.post(`${API_BASE_URL}/admin/tipovehiculo/tarifa`, {
        id_tipo_vehiculo: id,
        tarifa: parseFloat(tarifaNumerica),
      }, {
        headers: {
          Authorization: `${token}`, // Pasamos el token en el header
        },
      });

      message.success('Tarifa actualizada correctamente');
      fetchTiposVehiculos(); // Recargar la lista después de la actualización
    } catch (error) {
      message.error('Error al actualizar la tarifa');
    }
  };

  // Función para manejar el evento de tecla "Enter"
  const handleKeyDown = (e, id, tarifa) => {
    if (e.key === 'Enter') {
      handleEditTarifa(id, tarifa);
    }
  };

  // Definir las columnas de la tabla para mostrar los tipos de vehículos
  const columns = [
    { title: 'ID', dataIndex: 'id_tipo_vehiculo', key: 'id_tipo_vehiculo' },
    { title: 'Tipo de Vehículo', dataIndex: 'tipo_vehiculo', key: 'tipo_vehiculo' },
    {
      title: 'Tarifa',
      dataIndex: 'tarifa',
      key: 'tarifa',
      render: (tarifa, record) => {
        return (
          <NumericFormat
            value={tarifa}
            customInput={Input}
            thousandSeparator={true} // Separador de miles
            prefix="COP " // Prefijo para mostrar "COP" (puedes cambiarlo si lo necesitas)
            decimalScale={2} // Definir dos decimales
            fixedDecimalScale={true} // Asegura siempre dos decimales
            allowNegative={false} // No permitir valores negativos
            onBlur={(e) => handleEditTarifa(record.id_tipo_vehiculo, e.target.value)} // Al salir del campo, se actualiza la tarifa
            onKeyDown={(e) => handleKeyDown(e, record.id_tipo_vehiculo, e.target.value)} // Escuchar tecla Enter
          />
        );
      }
    },
    { title: 'Fecha de Creación', dataIndex: 'fecha_crea', key: 'fecha_crea' },
    { title: 'Usuario', dataIndex: 'usuario_nombre', key: 'usuario_nombre' },
  ];

  // Definir las pestañas usando la nueva propiedad 'items'
  const tabItems = [
    {
      key: "1",
      label: "Tipos de Vehículos",
      children: (
        <>
          <Button type="primary" onClick={showModal} style={{ marginBottom: 20 }}>
            Crear Nuevo Tipo de Vehículo
          </Button>

          <Table
            columns={columns}
            dataSource={tiposVehiculos}
            rowKey="id_tipo_vehiculo"
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize: 5,
              total,
              onChange: handlePageChange,
            }}
            scroll={{ x: 'max-content' }} // Permite el desplazamiento horizontal
            responsive // Habilita la responsividad en la tabla
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Configuración Cámaras",
      children: (
        <BluetoothPrinterConnect></BluetoothPrinterConnect>
      ),
    },
    {
      key: "3",
      label: "Configuración Sensores",
      children: (
        <p>Aquí irían otras configuraciones.</p>
      ),
    },
  ];

  return (
    <Card title="Configuraciones">
      {/* Usando Tabs con la nueva propiedad 'items' */}
      <Tabs defaultActiveKey="1" items={tabItems} />
      
      {/* Modal para agregar nuevo tipo de vehículo */}
      <Modal
        title="Crear Nuevo Tipo de Vehículo"
        open={isModalOpen} // Usamos 'open' en lugar de 'visible'
        onCancel={handleCancel} // Cerrar el modal
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreate}
        >
          <Form.Item
            name="tipo_vehiculo"
            label="Tipo de Vehículo"
            rules={[{ required: true, message: 'Por favor ingresa el tipo de vehículo' }]} >
            <Input />
          </Form.Item>

          <Form.Item
            name="tarifa"
            label="Tarifa"
            rules={[{ required: true, message: 'Por favor ingresa la tarifa' }]} >
            <NumericFormat
              customInput={Input}
              thousandSeparator={true} // Separador de miles
              prefix="COP " // Prefijo para mostrar "COP"
              decimalScale={2} // Definir dos decimales
              fixedDecimalScale={true} // Asegura siempre dos decimales
              allowNegative={false} // No permitir valores negativos
            />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            Crear Tipo de Vehículo
          </Button>
        </Form>
      </Modal>
    </Card>
  );
};

export default Configuraciones;
