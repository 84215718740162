import React, { useState } from 'react';

function BluetoothPrinterConnect() {
  const [status, setStatus] = useState('Esperando conexión...');
  const [connectedDevice, setConnectedDevice] = useState(null);

  const connectToPrinter = async () => {
    try {
      setStatus('Buscando dispositivos Bluetooth...');
      
      // Abrir la ventana de selección de dispositivo Bluetooth
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: ['serial_port', 'battery_service'] }] // Filtro por los servicios que soporta la impresora
      });

      // Si el dispositivo es seleccionado
      setStatus('Conectando a ' + device.name + '...');

      // Intentar conectar al dispositivo seleccionado
      const server = await device.gatt.connect();
      
      // Actualizar el estado con el nombre del dispositivo conectado
      setConnectedDevice(device.name);
      setStatus(`Conectado a: ${device.name}`);
      
      // Aquí puedes agregar lógica para interactuar con la impresora si es necesario (leer características, enviar comandos, etc.)

    } catch (error) {
      console.error('Error al conectar con el dispositivo', error);
      setStatus('Error al conectar con la impresora.');
    }
  };

  return (
    <div>
      <h1>Conectar a Impresora Térmica</h1>
      
      {/* Botón para iniciar la búsqueda de dispositivos */}
      <button onClick={connectToPrinter}>Conectar a la impresora</button>

      <p>{status}</p>

      {/* Si hay un dispositivo conectado, mostrar el nombre */}
      {connectedDevice && <p>Conectado a: {connectedDevice}</p>}
    </div>
  );
}

export default BluetoothPrinterConnect;
