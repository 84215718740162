import React, { useState } from 'react';
import { Card, Table, Input, Button, Row, Col, DatePicker, Tabs, Tag, Modal } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_BASE_URL from '../components/apiService';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const Reportes = () => {
  const [viajesData, setViajesData] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Para la búsqueda de matrícula
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [totalItems, setTotalItems] = useState(0); // Total de elementos para la paginación
  const [modalVisible, setModalVisible] = useState(false); // Controla la visibilidad del modal
  const [vehicleInfo, setVehicleInfo] = useState(null); // Almacena la información del vehículo
  const token = localStorage.getItem('token'); // Cambiar si el token se almacena en otro lugar
  const [totalTarifa, setTotalTarifa] = useState(0); // Añadir esta línea

// Función para manejar la búsqueda de los viajes
const fetchViajesData = async (fechaIni, fechaFin, page = 1, limit = 10, search = "") => {
  if (!fechaIni || !fechaFin) {
    console.error('Las fechas de inicio y fin son necesarias');
    return;
  }

  setLoading(true);
  try {
    // Formatear las fechas correctamente para la solicitud
    const formattedFechaIni = fechaIni ? fechaIni.format('YYYY-MM-DD') : '';
    const formattedFechaFin = fechaFin ? fechaFin.format('YYYY-MM-DD') : '';

    console.log("Fechas formateadas:", formattedFechaIni, formattedFechaFin);

    // Verificar que el token esté disponible
    if (!token) {
      console.error('Token no encontrado');
      return;
    }

    // Realizar la solicitud con los parámetros de paginación y otros filtros
    const response = await axios.get(
      `${API_BASE_URL}/admin/viajes/all`,
      {
        params: {
          page,
          limit,
          fecha_ini: formattedFechaIni,
          fecha_fin: formattedFechaFin,
          matricula: search,  // Parámetro de búsqueda por matrícula
        },
        headers: {
          'Authorization': `${token}`  // Asegúrate de que el token esté correctamente formateado
        }
      }
    );

    console.log("Respuesta de la API:", response.data); // Imprimir la respuesta para depuración

    // Verificar que la respuesta contiene la propiedad 'data' y 'viajes'
    if (response.data && response.data.data && Array.isArray(response.data.data.viajes)) {
      const viajes = response.data.data.viajes;

      // Calcular la suma total de las tarifas
      const totalTarifa = viajes.reduce((acc, viaje) => acc + (parseFloat(viaje.precio) || 0), 0);
      
      setViajesData(viajes);
      setTotalItems(response.data.data.totalItems); // Asegúrate de que la API devuelve el total de elementos
      setTotalTarifa(totalTarifa); // Almacenar el total de las tarifas
    } else {
      console.error("La respuesta de la API no contiene un array de viajes en 'data.viajes'");
      setViajesData([]);
      setTotalItems(0); // Si no hay datos, reiniciamos el total de elementos
      setTotalTarifa(0); // Reiniciar el total de tarifas
    }
  } catch (error) {
    console.error('Error al obtener los viajes:', error);
    setViajesData([]); // En caso de error, inicializamos con un array vacío
    setTotalItems(0);
    setTotalTarifa(0); // Reiniciar el total de tarifas
  } finally {
    setLoading(false);
  }
};

  // Función para manejar los cambios en el rango de fechas
  const handleDateChange = (dates) => {
    console.log("Fecha seleccionada:", dates); // Depuración de las fechas seleccionadas
    if (dates && dates.length === 2) {
      setFechaInicio(dates[0]);
      setFechaFin(dates[1]);
    } else {
      setFechaInicio(null);
      setFechaFin(null);
    }
  };

  // Función para manejar el cambio en la búsqueda de matrícula
  const handleSearchChange = (e) => {
    console.log("Cambio en búsqueda de matrícula:", e.target.value); // Depuración del valor de búsqueda
    setSearchTerm(e.target.value);
  };

  // Función para ejecutar la búsqueda al hacer clic en el botón
  const handleSearch = () => {
    console.log("Buscar con:", fechaInicio, fechaFin, searchTerm); // Depuración antes de llamar a la API
    if (fechaInicio && fechaFin) {
      fetchViajesData(fechaInicio, fechaFin, 1, 10, searchTerm); // Pasa la matrícula como parámetro de búsqueda
    } else {
      console.error('Selecciona un rango de fechas primero');
    }
  };

  // Filtro de búsqueda general en columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  // Función para manejar el cambio de página
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchViajesData(fechaInicio, fechaFin, page, 10, searchTerm); // Volver a cargar los viajes con la nueva página
  };

  // Función para verificar que los valores numéricos sean válidos antes de aplicar toFixed
  const handleCoste = (value) => {
    if (value && !isNaN(value)) {
      // Formateamos el número con separadores de miles y dos decimales
      return `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}`;
    }
    return '$0.00'; // Valor por defecto en caso de que no sea un número válido
  };

  // Función para mostrar la información del vehículo
  const showVehicleInfo = async (vehiculo_id) => {
    setLoading(true);
    setModalVisible(true);
    try {
      // Realizar la consulta a la API para obtener la información del vehículo
      const response = await axios.get(
        `${API_BASE_URL}/admin/vehiculo/${vehiculo_id}`,
        {
          headers: {
            'Authorization': `${token}`  // Asegúrate de que el token esté correctamente formateado
          }
        }
      );
  
      // console.log("Respuesta de la API del vehículo:", response.data.data.vehiculo);
  
      // Aquí almacenamos la información del vehículo en el estado
      setVehicleInfo({
        ...response.data.data.vehiculo,
        imagen: response.data.imagen || 'https://via.placeholder.com/150' // Imagen de prueba si no se obtiene una
      });
    } catch (error) {
      console.error('Error al obtener la información del vehículo:', error);
      setVehicleInfo(null); // En caso de error, vaciar la información del vehículo
    } finally {
      setLoading(false);
    }
  };

  // Columnas para la tabla de viajes con el filtro y el formato de "Factura Electrónica"
  const viajesColumns = [
    { title: 'ID Viaje', dataIndex: 'idviajes', key: 'idviajes', ...getColumnSearchProps('idviajes') },
    { title: 'Recorrido', dataIndex: 'trayecto', key: 'trayecto', ...getColumnSearchProps('trayecto') },
    { title: 'Matricula', dataIndex: 'matricula', key: 'matricula', ...getColumnSearchProps('matricula') },
    { title: 'Tipo de vehiculo', dataIndex: 'tipo_vehiculo', key: 'tipo_vehiculo', ...getColumnSearchProps('tipo_vehiculo') },
    { title: 'Conductor', dataIndex: 'nombre', key: 'nombre', ...getColumnSearchProps('nombre') },
    { title: 'Telefono', dataIndex: 'telefono', key: 'telefono', ...getColumnSearchProps('telefono') },
    {
      title: 'Tarifa',
      dataIndex: 'precio',
      key: 'precio',
      ...getColumnSearchProps('precio'),
      render: (text) => handleCoste(text), // Aquí formateamos el valor
    },
    {
      title: 'F Electrónica',
      dataIndex: 'factura_electronica', // El nombre del campo en tu dataSource
      key: 'factura_electronica',
      render: (text) => {
        // Si es 1, significa "Sí", color verde; si es 0, significa "No", color rojo
        return (
          <Tag color={text === 1 ? 'green' : 'red'}>
            {text === 1 ? 'Sí' : 'No'}
          </Tag>
        );
      },
    },
    
    { title: 'Fecha de Creación', dataIndex: 'vehiculo_fecha_creacion', key: 'vehiculo_fecha_creacion', ...getColumnSearchProps('vehiculo_fecha_creacion') },
    {
      title: 'Acciones',
      key: 'vehiculo_id',
      render: (text, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => showVehicleInfo(record.vehiculo_id)} // Mostrar detalles del vehículo
        >
          Ver más
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Card title="Reporte de Viajes">
        <Row gutter={2}>
          <Col span={12}>
            <RangePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
            />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleSearch}
              loading={loading}
              style={{ width: '100%' }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Viajes" key="1">
            <Table
            rowKey="idviajes"
              columns={viajesColumns}
              dataSource={viajesData}
              pagination={{
                current: currentPage,
                total: totalItems,
                pageSize: 10,
                onChange: handlePageChange,
              }}
              loading={loading}
              scroll={{ x: 'max-content' }} // Habilitar desplazamiento horizontal en pantallas más pequeñas
              footer={() => (
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Total: {handleCoste(totalTarifa)} {/* Mostrar el total de tarifas */}
                </div>
              )}
            />
          </TabPane>
        </Tabs>
      </Card>

      {/* Modal de detalles del vehículo */}
      <Modal
        title="Información del Vehículo"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
      <img
        src={vehicleInfo && vehicleInfo.foto ? `${API_BASE_URL}/uploads/${vehicleInfo.foto}` : 'assets/course-default.jpg'}
        alt="Imagen del vehículo" width={200}
      />
        <p><strong>Matricula:</strong> {vehicleInfo ? vehicleInfo.matricula : 'N/A'}</p>
        <p><strong>Tipo de Vehículo:</strong> {vehicleInfo ? vehicleInfo.tipo_vehiculo : 'N/A'}</p>
        <p><strong>Conductor:</strong> {vehicleInfo ? vehicleInfo.nombre : 'N/A'}</p>
        <p><strong>Telefono:</strong> {vehicleInfo ? vehicleInfo.telefono : 'N/A'}</p>
        <p><strong>Fecha de registro:</strong> {vehicleInfo ? vehicleInfo.vehiculo_fecha_creacion : 'N/A'}</p>
      </Modal>
    </div>
  );
};

export default Reportes;
