import React from 'react';
import { Layout, Typography, Row, Col, Card, Image, Modal, Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Tutoriales = ({ showModal }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content
        style={{
          padding: '24px',
          margin: 0,
          minHeight: 280,
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Title level={2}>¡Bienvenido a los tutoriales!</Title>
        <Text>Aquí tienes algunos videos para aprender a usar la aplicación:</Text>

        <Row gutter={[8, 16]} style={{ marginTop: 20 }}>
          {/* Video 1: Conociendo toda la interfaz */}
          <Col xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              }}
              cover={
                <Image
                  alt="Conociendo toda la interfaz"
                  src="https://img.youtube.com/vi/VIDEO_ID_1/hqdefault.jpg"
                  preview={false}
                />
              }
              onClick={() => showModal('https://www.youtube.com/embed/VIDEO_ID_1')}
            >
              <Title level={4}>1. Conociendo toda la interfaz</Title>
              <Text>Este video te guía a través de la interfaz completa de la aplicación.</Text>
            </Card>
          </Col>

          {/* Video 2: Administración de usuarios */}
          <Col xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              }}
              cover={
                <Image
                  alt="Administración de usuarios"
                  src="https://img.youtube.com/vi/VIDEO_ID_2/hqdefault.jpg"
                  preview={false}
                />
              }
              onClick={() => showModal('https://www.youtube.com/embed/VIDEO_ID_2')}
            >
              <Title level={4}>2. Administración de usuarios</Title>
              <Text>Este video nos muestra cómo administrar usuarios en la plataforma.</Text>
            </Card>
          </Col>

          {/* Video 3: Jornadas */}
          <Col xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              }}
              cover={
                <Image
                  alt="Administración de jornadas"
                  src="https://img.youtube.com/vi/VIDEO_ID_3/hqdefault.jpg"
                  preview={false}
                />
              }
              onClick={() => showModal('https://www.youtube.com/embed/VIDEO_ID_3')}
            >
              <Title level={4}>3. Administración de jornadas</Title>
              <Text>Aprende a gestionar las jornadas de trabajo en la plataforma.</Text>
            </Card>
          </Col>

          {/* Video 4: Reportes */}
          <Col xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              }}
              cover={
                <Image
                  alt="Generación de reportes"
                  src="https://img.youtube.com/vi/VIDEO_ID_4/hqdefault.jpg"
                  preview={false}
                />
              }
              onClick={() => showModal('https://www.youtube.com/embed/VIDEO_ID_4')}
            >
              <Title level={4}>4. Generación de reportes</Title>
              <Text>Aprende a generar y descargar reportes desde la plataforma.</Text>
            </Card>
          </Col>

          {/* Video 5: Configuración */}
          <Col xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              }}
              cover={
                <Image
                  alt="Configuración"
                  src="https://img.youtube.com/vi/VIDEO_ID_5/hqdefault.jpg"
                  preview={false}
                />
              }
              onClick={() => showModal('https://www.youtube.com/embed/VIDEO_ID_5')}
            >
              <Title level={4}>5. Configuración</Title>
              <Text>Instrucciones para personalizar la aplicación según tus necesidades.</Text>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default Tutoriales;
