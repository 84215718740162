// Elimina el uso de defineConfig y crea el tema directamente

const theme = {
    token: {
      colorPrimary: '#007bff',  // Color primario de la aplicación
      colorLink: '#1DA57A',      // Color de los enlaces
      fontFamily: 'Arial, sans-serif',  // Fuente de la aplicación
    },
  };
  
  export default theme;
  