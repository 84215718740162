import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Divider, Row, Col, DatePicker, message, Modal, Table } from 'antd';
import { NumericFormat } from 'react-number-format';  // Importación correcta
import axios from 'axios'; // Necesitarás instalar axios
import API_BASE_URL from '../components/apiService';
import { SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

function GastosOperacion() {
  // Estados para los valores del formulario
  const [totalAmount, setTotalAmount] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [fechaIni, setFechaIni] = useState(null);  // Estado para la fecha inicio
  const [fechaFin, setFechaFin] = useState(null);  // Estado para la fecha fin

  // Estado para el modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Estados para los datos de la tabla
  const [operationalCosts, setOperationalCosts] = useState([]);
  const [total, setTotal] = useState(0); // Total de registros
  const [page, setPage] = useState(1);   // Página actual
  const [loading, setLoading] = useState(false); // Estado de carga

  // Función para obtener los gastos desde la API
  const fetchGastos = async (page) => {
    setLoading(true);
    const token = localStorage.getItem('token'); // Obtener el token desde localStorage

    try {
      const response = await axios.get(
        `${API_BASE_URL}/admin/gastos/all`,
        {
          params: {
            page,
            limit: 10, // Límite de resultados por página
          },
          headers: {
            Authorization: `${token}` // Pasamos el token en los encabezados
          }
        }
      );

      if (response.data.success) {
        setOperationalCosts(response.data.data.gastos); // Datos de los gastos
        setTotal(response.data.data.count);  // Total de registros
      } else {
        message.error('Error al obtener los datos');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud', error);
      message.error('Hubo un error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  // Llamar a la API al cambiar de página
  useEffect(() => {
    fetchGastos(page);
  }, [page]);

  // Función para manejar el cambio de página en la tabla
  const handlePageChange = (page) => {
    setPage(page);  // Actualizamos la página actual
  };

  // Función para filtrar las columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = operationalCosts.filter((record) =>
      record[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase())
    );
    setOperationalCosts(filteredData);
  };
  // Filtro de búsqueda general en columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });
  // Definir las columnas de la tabla


  // ...
  
  const columns = [
    {
      title: 'Ingreso total del mes',
      dataIndex: 'monto_total_periodo',
      key: 'monto_total_periodo',
      render: (amount) => (
        <NumericFormat 
          value={amount} 
          displayType="text"
          thousandSeparator={true} 
          prefix="$" 
          decimalScale={2}
          fixedDecimalScale 
        />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, 'monto_total_periodo')}
            placeholder={`Buscar Monto`}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, 'monto_total_periodo')}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Resetear
          </Button>
        </div>
      ),
      filterIcon: () => <i className="anticon anticon-search" />,
    },
    {
      title: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
      ...getColumnSearchProps('mes')
    },
    {
      title: 'Costo operacional',
      dataIndex: 'total_deducciones',
      key: 'total_deducciones',
      render: (amount) => (
        <NumericFormat 
          value={amount} 
          displayType="text"
          thousandSeparator={true} 
          prefix="$" 
          decimalScale={2}
          fixedDecimalScale 
        />
      ),
    },
    {
      title: 'Monto final',
      dataIndex: 'monto_final',
      key: 'monto_final',
      render: (amount) => (
        <NumericFormat 
          value={amount} 
          displayType="text"
          thousandSeparator={true} 
          prefix="$" 
          decimalScale={2}
          fixedDecimalScale 
        />
      ),
    }
  ];
  

  // Configuración de la paginación
  const paginationConfig = {
    current: page,
    total: total,
    pageSize: 10,
    onChange: handlePageChange, // Cambiar de página
  };

  // Función para obtener el monto total
  const handleGetTotalAmount = async () => {
    if (fechaIni && fechaFin) {
      const fechaIniFormatted = fechaIni.format('YYYY-MM-DD');
      const fechaFinFormatted = fechaFin.format('YYYY-MM-DD');
      
      const token = localStorage.getItem('token'); // Obtener el token desde el localStorage

      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/ingresos/all?fecha_ini=${fechaIniFormatted}&fecha_fin=${fechaFinFormatted}`,
          {
            headers: {
              Authorization: `${token}` // Pasamos el token en los encabezados
            }
          }
        );
        if (response.data.success) {
          const totalPrecio = response.data.data.jornadas[0].total_precio;
          setTotalAmount(totalPrecio); // Actualizamos el monto total con la respuesta
          message.success('Monto total obtenido exitosamente');
        } else {
          message.error('Error al obtener el monto total');
        }
      } catch (error) {
        console.error('Error al obtener el monto total', error);
        message.error('Error al obtener el monto total');
      }
    } else {
      message.error('Por favor, seleccione un rango de fechas.');
    }
  };

  // Función para enviar el gasto operacional al API
  const handleSubmit = async () => {
    const token = localStorage.getItem('token'); // Obtener el token desde el localStorage
  
    const payload = {
      monto_total_periodo: totalAmount,  // El monto total obtenido
      fecha_inicial: fechaIni.format('YYYY-MM-DD'),  // Formatear fecha
      fecha_final: fechaFin.format('YYYY-MM-DD'),  // Formatear fecha
      descripcion_gasto_operacional: description,  // Descripción del gasto
      total_gasto_operacional: amount,  // Sumar el total de los gastos operacionales
    };
  
    try {
      const response = await axios.post(
    `${API_BASE_URL}/admin/gastooperacional`, 
        payload,
        {
          headers: {
            Authorization: `${token}`,  // Pasamos el token en los encabezados
          }
        }
      );
      
      if (response.data.success) {
        message.success('Gasto operacional creado con éxito');
        
        // Limpiar el formulario
        setTotalAmount('');
        setDescription('');
        setAmount('');
        setFechaIni(null);
        setFechaFin(null);
  
        // Recargar la tabla
        fetchGastos(page);  // Vuelve a llamar al servicio para actualizar los datos
  
        // Opcional: Cerrar el modal después de enviar
        setIsModalVisible(false);
      } else {
        message.error('Hubo un error al crear el gasto operacional');
      }
    } catch (error) {
      console.error('Error al enviar los datos al API', error);
      message.error('Hubo un error al enviar los datos');
    }
  };
  

  // Función para manejar la apertura del modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Función para manejar el cierre del modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <div style={{ padding: '10px' }}>
      <Title level={5}>Ingresos mensuales y gastos operacionales</Title>

      {/* Botón para abrir el modal con el formulario */}
      <Button type="primary" onClick={showModal} style={{ marginBottom: 20 }} block>
        Agregar Gasto Operacional
      </Button>

      {/* Tabla de gastos operacionales */}
      <Table
        columns={columns}
        dataSource={operationalCosts}
        rowKey="idgasto_operacional"
        pagination={paginationConfig} // Paginación
        loading={loading} // Mostrar carga mientras se obtienen los datos
        scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
        responsive={true}  // Habilita la responsividad de la tabla
      />

      <Divider />

      {/* Modal para el formulario de gastos operacionales */}
      <Modal
        title="Agregar Gasto Operacional"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="90%"  // Hace que el modal sea más pequeño en dispositivos móviles
      >
        <Form layout="vertical">
          {/* Campo para seleccionar el periodo */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Fecha de Inicio">
                <DatePicker
                  style={{ width: '100%' }}
                  value={fechaIni}
                  onChange={(date) => setFechaIni(date)}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Fecha de Fin">
                <DatePicker
                  style={{ width: '100%' }}
                  value={fechaFin}
                  onChange={(date) => setFechaFin(date)}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Botón para obtener el monto total */}
          <Form.Item>
            <Button type="primary" onClick={handleGetTotalAmount} block>
              Obtener Monto Total
            </Button>
          </Form.Item>

          {/* Campo para el monto total */}
          <Form.Item label="Monto Total">
            <NumericFormat
              value={totalAmount}
              displayType="input"
              thousandSeparator={true}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              customInput={Input}  // Usamos Input de Ant Design
              placeholder="Monto Total"
              readOnly  // Hacemos este campo solo lectura
            />
          </Form.Item>

          {/* Campos para el gasto operacional */}
          <Form.Item label="Descripción del Gasto Operacional">
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Ejemplo: Pago de nómina"
            />
          </Form.Item>

          <Form.Item label="Monto del Gasto Operacional">
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              thousandSeparator={true}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              customInput={Input}  // Usamos Input de Ant Design
              placeholder="Monto del gasto operacional"
            />
          </Form.Item>

          {/* Botón para enviar el gasto operacional */}
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block>
              Enviar Gasto Operacional
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default GastosOperacion;
